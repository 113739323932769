import { z } from "zod";
import { IRequestInit } from "./utils";

export interface IApiCall extends z.output<typeof IApiCall> { }
const IApiCall = z
  .object({
    call: z.string(),
    host: z.string().nonempty().optional(),
    path: z.string().nonempty().optional(),
    ext: z.string().nonempty().optional(),
    params: z.record(z.string(), z.any()).optional(),
    options: IRequestInit.optional(),
  })
  .strip();

const CallConfig = IApiCall.deepPartial();
export type IApiConfig = z.output<typeof IApiConfig>;
const IApiConfig = IApiCall.extend({
  call: z.never().optional(),
  host: z.string().url().optional(),
  overrides: z.record(z.string(), CallConfig).optional().default({}),
}).transform(({ overrides, ...defaults }) => ({ overrides, defaults }));

export type IApiConfigurations = z.output<typeof IApiConfigurations>;
const IApiConfigurations = z.object({
  aws: IApiConfig,
  dev: IApiConfig,
  rest: IApiConfig,
  live: IApiConfig,
  lmn: IApiConfig,
  static: IApiConfig,
});


export type IAuthConfig = z.output<typeof IAuthConfig>;
export const IAuthConfig = z.object({
  domain: z.string(),
  clientId: z.string(),
  audience: z.string(),
});


export type IConfigFile = z.output<typeof IConfigFile>;
export const IConfigFile = z.object({
  auth: IAuthConfig,
  api: IApiConfigurations,
});


